const replaceParams = (template: string, params: Record<string, unknown> = {}) => {
  return Object.keys(params).reduce(
    (acc, key) => acc.replace(`:${key}`, String(params[key])),
    template.replace('*', ''),
  );
};
// Based on https://davidtimms.github.io/programming-languages/typescript/2020/11/20/exploring-template-literal-types-in-typescript-4.1.html
type PathParams<Path extends string> = Path extends `:${infer Param}/${infer Rest}`
  ? Param | PathParams<Rest>
  : Path extends `:${infer Param}`
    ? Param
    : Path extends `${infer _Prefix}:${infer Rest}`
      ? PathParams<`:${Rest}`>
      : never;

type PathArgs<Path extends string> = Record<PathParams<Path>, string>;
interface VictorRoute<P extends string> {
  template: P | `${P}/*`;
  buildLink: (params: PathParams<P> extends never ? void : PathArgs<P>) => string;
  buildLinkWithSearch: (params: PathParams<P> extends never ? void : PathArgs<P>) => string;
}

const defineRoute = <P extends string>(
  template: P,
  options: { exact: boolean; parentRoute: VictorRoute<string> | undefined } = {
    exact: true,
    parentRoute: undefined,
  },
): VictorRoute<P> => {
  type Params = PathParams<P>;
  const search = window.location.search;
  const { exact, parentRoute } = options;

  return {
    template: exact ? template : `${template}/*`,
    buildLink: (params: Params extends never ? void : PathArgs<P>) =>
      `${parentRoute ? parentRoute.buildLink() : ''}/${replaceParams(template, params as PathArgs<P> | undefined)}`,
    buildLinkWithSearch: (params: Params extends never ? void : PathArgs<P>) =>
      `${parentRoute ? parentRoute.buildLink() : ''}/${replaceParams(
        template,
        params as PathArgs<P> | undefined,
      )}${search}`,
  };
};

export const RouteConfig = {
  Login: defineRoute('login'),
  Logout: defineRoute('logout'),
  NoAccess: defineRoute('no-access'),
  Requests: defineRoute('requests'),
  RequestDetail: defineRoute('requests/:vid'),
  Quotes: defineRoute('quotes'),
  QuoteNew: defineRoute('quotes/new'),
  QuoteClone: defineRoute('quotes/:opQuote/clone'),
  Deals: defineRoute('deals'),
  DealNew: defineRoute('deals/new'),
  DealDetails: defineRoute('deals/:deal'),
};
