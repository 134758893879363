import { Logout } from '@mui/icons-material';
import { AppBar, Toolbar, Typography, Tooltip, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '~/routes/routeConfig';
import NavigationTabs from './NavigationTabs';
import useDataTestId from '~/hooks/useDataTestId';

const DashboardHeader = ({ dataTestId }: { dataTestId?: string }) => {
  const dt = useDataTestId(dataTestId);
  const navigate = useNavigate();
  const theme = useTheme();
  const logout = () => {
    navigate(RouteConfig.Logout.buildLink());
  };

  return (
    <AppBar color="secondary">
      <Toolbar>
        <Typography variant="h4" sx={{ mr: 4 }}>
          V-CONNECT
        </Typography>
        <NavigationTabs
          tabs={[
            {
              label: 'Requests',
              path: RouteConfig.Requests.buildLink(),
            },
            {
              label: 'Quotes',
              path: RouteConfig.Quotes.buildLink(),
            },
            {
              label: 'Deals',
              path: RouteConfig.Deals.buildLink(),
            },
          ]}
          dataTestId={dt('navigation')}
        />
        <div style={{ flexGrow: 1 }} />
        <Tooltip title="Log out">
          <IconButton
            sx={{ color: theme.palette.secondary.contrastText, '&:hover': { color: theme.palette.primary.main } }}
            onClick={logout}
          >
            <Logout />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
