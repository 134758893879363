import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import lazyImport from '~/utils/lazyImport';
import AppRoot from './AppRoot';
import { RouteConfig } from './routeConfig';
import AuthGuard from './guards/AuthGuard';
import DashboardLayout from '~/views/dashboard/layout/DashboardLayout';

export { RouteConfig };

const LoginPage: React.FC<{ dataTestId?: string }> = lazyImport(() => import('../views/auth/LoginView'));
const LogoutPage = lazyImport(() => import('../views/auth/LogoutView'));
const NoAccessPage = lazyImport(() => import('../views/auth/NoAccessView'));
const PendingRequestsPage: React.FC<{ dataTestId?: string }> = lazyImport(
  () => import('../views/dashboard/pendingRequests/PendingRequestsView'),
);
const PendingRequestDetails: React.FC<{ dataTestId?: string }> = lazyImport(
  () => import('../views/dashboard/pendingRequests/PendingRequestDetails'),
);
const QuotesView: React.FC<{ dataTestId?: string }> = lazyImport(() => import('../views/dashboard/quotes/QuotesView'));
const QuoteCreate: React.FC<{ dataTestId?: string }> = lazyImport(
  () => import('../views/dashboard/quotes/QuoteCreate'),
);
const QuoteClone = lazyImport(() => import('../views/dashboard/quotes/QuoteClone'));
const DealsView: React.FC<{ dataTestId?: string }> = lazyImport(() => import('../views/dashboard/deals/DealsView'));
const DealCreate: React.FC<{ dataTestId?: string }> = lazyImport(() => import('../views/dashboard/deals/DealCreate'));
const DealDetails: React.FC<{ dataTestId?: string }> = lazyImport(() => import('../views/dashboard/deals/DealDetails'));

const routes: RouteObject[] = [
  {
    path: '*',
    element: <AppRoot />,
    children: [
      {
        path: RouteConfig.Logout.template,
        element: <LogoutPage />,
      },
      {
        path: RouteConfig.Login.template,
        element: <LoginPage dataTestId={'login'} />,
      },
      {
        path: RouteConfig.NoAccess.template,
        element: <NoAccessPage />,
      },
      {
        path: '*',
        element: (
          <AuthGuard>
            <DashboardLayout dataTestId={'dashboard'} />
          </AuthGuard>
        ),
        children: [
          { path: RouteConfig.Requests.template, element: <PendingRequestsPage dataTestId={'pending-requests'} /> },
          {
            path: RouteConfig.RequestDetail.template,
            element: <PendingRequestDetails dataTestId={'pending-request-details'} />,
          },
          { path: RouteConfig.QuoteNew.template, element: <QuoteCreate dataTestId={'new-quote'} /> },
          { path: RouteConfig.QuoteClone.template, element: <QuoteClone /> },
          { path: RouteConfig.Quotes.template, element: <QuotesView /> },
          { path: RouteConfig.Deals.template, element: <DealsView /> },
          { path: RouteConfig.DealNew.template, element: <DealCreate /> },
          { path: RouteConfig.DealDetails.template, element: <DealDetails /> },
          { path: '*', element: <Navigate to={RouteConfig.Requests.buildLink()} replace /> },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
