import { merge } from 'lodash';
import Select from './Select';
import TextField from './TextField';
import Tabs from './Tabs';
import Slider from './Slider';
import Checkbox from './Checkbox';
import Container from './Container';
import { Components } from '@mui/material';
import { Theme } from '@emotion/react';

export default function ComponentsOverrides() {
  return merge(Select(), TextField(), Tabs(), Slider(), Checkbox(), Container()) as Partial<Components<Theme>>;
}
