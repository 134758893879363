import { createBreakpoints } from '@mui/system';
import { Theme } from '@emotion/react';
import { Components } from '@mui/material';

function Container(): Partial<Components<Theme>> {
  const breakpoints = createBreakpoints({});

  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          [breakpoints.up('lg')]: {
            maxWidth: '100%',
          },
          [breakpoints.up('sm')]: {
            padding: '0 32px',
          },
        },
      },
    },
  };
}

export default Container;
